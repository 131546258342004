/*
 * Application global variables.
 */

// Angular Material custom theme
// See https://material.angular.io/guide/theming for more details.
//
// You can also read https://medium.com/@tomastrajan/the-complete-guide-to-angular-material-themes-4d165a9d24d1
// for more insight about Angular Material theming.
@use "@angular/material" as mat;
@import "@angular/material/theming";

@import "palette";
@import "typography";

@include mat-core($urvial-typography);

//Custom palette

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$app-primary: mat-palette($urvial-primary-color, 1000);
// stylelint-disable-next-line value-keyword-case
$app-accent: mat-palette($urvial-primary-color, 600);

// The warn palette is optional (defaults to red).
$app-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

// Background colors
//$app-background-dark: #282a36;
$app-background-dark: #1f4968;
//$app-background-dark: #9C2F60;
$app-background-gray: #f9f9f9;
$app-background-gray2: #a2a7ae;
$app-background-light: #f5f5f5;
$app-background-text: #5f6368;
$app-background-menu: #9c2f60;
$app-background-menu2: #ecd5df;
$app-background-red: #c90000;

.mat-urvial-green {
  color: #70b45f;
}

.mat-urvial-orange {
  color: rgb(249, 169, 38);
}

.mat-urvial-red {
  color: #c90000;
}
