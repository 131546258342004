/*Docs:  https://material.angular.io/guide/typography#typography-levels */
/*Github material typography file https://github.com/angular/components/blob/master/src/material/core/typography/_typography.scss */

$urvial-typography: mat-typography-config(
  $font-family: "OpenSans, sans-serif",
  $display-4: mat-typography-level(112px, 112px, 300),
  /* 112px, one-off header, usually at the top of the page (e.g. a hero header). */
    $display-3: mat-typography-level(66px, 56px, 400),
  /* 56px, one-off header, usually at the top of the page (e.g. a hero header). */
    $display-2: mat-typography-level(55px, 48px, 400),
  /* 45px, one-off header, usually at the top of the page (e.g. a hero header). */
    $display-1: mat-typography-level(44px, 40px, 400),
  /* 34px, one-off header, usually at the top of the page (e.g. a hero header). */
    $headline: mat-typography-level(34px, 32px, 400),
  /* Section heading corresponding to the <h1> tag. */ $title:
    mat-typography-level(20px, 32px, 500),
  /* Section heading corresponding to the <h2> tag. */ $subheading-2:
    mat-typography-level(16px, 22px, 500),
  /* Section heading corresponding to the <h3> tag. */ $subheading-1:
    mat-typography-level(15px, 24px, 400),
  /* Section heading corresponding to the <h4> tag. */ $body-1:
    mat-typography-level(14px, 30px, 400),
  /* Base body text.*/ $body-2: mat-typography-level(14px, 24px, 500),
  /* Bolder body text. */ $caption: mat-typography-level(12px, 20px, 400),
  /* Smaller body and hint text. */ $button:
    mat-typography-level(14px, 14px, 500),
  /* Buttons and anchors. */
    // Line-height must be unit-less fraction of the font-size. $input: mat-typography-level(inherit, 1.125, 400),
);
