/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Theme variables, must be included before the libraries to allow overriding defaults
@use "@angular/material" as mat;
@use "@ng-matero/extensions" as mtx;

@import "theme/theme-variables";

// Angular Material > Custom theme
// The mixins below must be included once so we separated them from the variables
@import "@angular/material/theming";

// Angular Material > Custom fonts
@font-face {
  font-family: "Poppins";
  src:
    local("Poppins"),
    url(./assets/fonts/poppins/Poppins-Regular.ttf) format("truetype");
}

// Angular Material > Icons
@import "material-icons/iconfont/material-icons.css";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// @include mat.core();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

@include mtx.all-component-themes($app-theme);

// Theme customization
@import "theme/theme";
